<template>
  <div style="padding-bottom: 250px">
    <RocketLoad
      :full="true"
      v-if="isLoading"
    />
    <template v-else>
      <div class="grid align-items-center justify-content-between">
        <div class="flex pb-3">
          <h1>Accept Job</h1>
        </div>
        <div class="flex pb-3 justify-content-end align-items-center"></div>
      </div>
      <Divider />
      <form
        @submit.prevent="submitHandler"
        v-form-enter
      >
        <div class="p-fluid formgrid grid">
          <div class="field col-12 sm:col-6">
            <label class="label">Start Date</label>
            <div class="p-inputgroup">
              <Calendar
                v-model="date_start"
                placeholder="Start Date"
                :showIcon="true"
                dateFormat="yy-mm-dd"
              />
            </div>
          </div>

          <div class="field col-12 sm:col-6">
            <label class="label">Due Date</label>
            <Calendar
              v-model="date_due"
              placeholder="Due Date"
              :showIcon="true"
              dateFormat="yy-mm-dd"
            />
          </div>
        </div>
        <div class="flex justify-content-between">
          <Button
            type="submit"
            label="Accept Job"
            :input-class="['is-primary']"
          />
          <Button
            class="p-button-text p-button-secondary"
            @click="clearSliderContent"
          >
            CANCEL
          </Button>
        </div>
      </form>
    </template>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
export default {
  name: "AcceptJobSlider",
  components: {},
  data() {
    return {
      date_due: "",
      date_start: "",
      submitted: false,
      isLoading: false,
    };
  },
  validations: {
    date_start: {
      required,
    },
    date_due: {
      required,
    },
  },
  mounted() {
    this.date_start = this.$moment(new Date()).format("YYYY-MM-DD");
    this.date_due = this.$moment(this.job?.date_due?.date).format("YYYY-MM-DD");
    // Check if date_due is less than date_start
    if (this.date_due < this.date_start) {
      // If date_due is less, set it to be the same as date_start
      this.date_due = this.date_start;
    }
  },
  methods: {
    submitHandler() {
      this.submitted = true;
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.$toast.add({
          severity: "error",
          summary: "Please complete all the required fields",
          life: 3000,
        });
      } else {
        this.isLoading = true;
        const date_start = new Date(this.date_start)
          .toISOString()
          .split("T")[0];
        const date_due = new Date(this.date_due).toISOString().split("T")[0];
        var form = new FormData();
        form.append("date_start", date_start);
        form.append("date_due", date_due);

        this.$axios
          .post(
            process.env.VUE_APP_ROOT_API + "/v1/jobs/" + this.uid + "/accept",
            form,
            {
              headers: { "Content-type": "application/x-www-form-urlencoded" },
            }
          )
          .then((response) => {
            this.isLoading = false;
            this.$toast.add({
              severity: "success",
              summary: `Job Accepted!`,
              life: 3000,
            });
            this.$router.push("/job/" + this.uid);
            this.clearSliderContent();
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response && error.response.status === 401) {
              console.error("NOOOOOOOOO!");
            } else {
              // Handle error however you want
            }
            console.error(error);
          });
      }
    },
  },
  computed: {
    job() {
      return this.$store.getters.selectedObject;
    },
    uid() {
      return this.job.uid;
    },
  },
};
</script>